import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';

import { logGAGoal, gaGoals } from '../../helpers/ga';
import { isPreviewMode } from '../../helpers/preview';

import LiveEvent from './liveEvent';
import PreEvent from './preEvent';
import PostEvent from './postEvent';
import Loader from '../../components/common/loader';
import Header from '../../components/header';

import style from './style';

const { LIVE } = gaGoals;

const getPageData = (eventStatus) => {
  if (isPreviewMode()) {
    return <LiveEvent />;
  }

  switch (eventStatus) {
  case 'live': {
    logGAGoal(LIVE);
    return <LiveEvent />;
  }
  case 'pre':
    return <PreEvent />;
  case 'post':
    return <PostEvent />;
  default:
    return <Loader />;
  }
};

const Home = ({ eventStatus, frontPageUrl }) => {
  const eventStyle = {
    backgroundImage: `url(${frontPageUrl})`,
  };

  return (
    <Fragment>
      <Header left={null} transparent floating inverted />
      <div className={style.home} style={eventStyle}>
        {getPageData(eventStatus)}
      </div>
    </Fragment>
  );
};


export default connect(
  ({ event: { eventStatus, images: { frontPageUrl } = {} } }) => ({ eventStatus, frontPageUrl }),
)(Home);
