/* eslint-disable react/no-danger */
import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';

import Button from '../../../components/common/button';
import Intl, { translate } from '../../../components/common/intl';

import { route } from '../../../helpers/router';
import { isUndefined } from '../../../helpers/types';

import style from '../style';

const LiveEvent = (props) => {
  const {
    geofence,
    geolocationEnabled,
    onboardingDisplayed,
    isTicketMode,
  } = props;

  const start = () => {
    if (isUndefined(onboardingDisplayed) || !onboardingDisplayed) {
      route('/onboarding');
    } else if (geofence.enabled && !geolocationEnabled) {
      route('/location');
    } else if (isTicketMode) {
      route('/findme/confirm');
    } else {
      route('/findme');
    }
  };

  return (
    <Fragment>
      <div className={style.container}>
        <h1
          className="textContainer inverted"
          dangerouslySetInnerHTML={{
            __html: translate(
              'I18N_LIVE_EVENT_TITLE',
              'Control our Fan Cam to take private group photos',
            ),
          }}
        />
        <h3
          className="textContainer inverted"
          dangerouslySetInnerHTML={{
            __html: translate(
              'I18N_LIVE_EVENT_SUBTITLE',
              'Capture your moment in the crowd',
            ),
          }}
        />
        <Button primary onClick={start}>
          <Intl textKey="I18N_START_BUTTON">Get Started</Intl>
        </Button>
      </div>
    </Fragment>
  );
};

export default connect(
  ({
    event: { geofence },
    geolocationEnabled,
    onboardingDisplayed,
    isTicketMode,
  }) => ({
    geofence,
    geolocationEnabled,
    onboardingDisplayed,
    isTicketMode,
  }),
)(LiveEvent);
