/* eslint-disable react/no-danger */
import { h } from 'preact';
import { translate } from '../../../components/common/intl';

import style from '../style';

const PreEvent = () => (
  <div className={style.container}>
    <h1
      className="textContainer inverted"
      dangerouslySetInnerHTML={{
        __html: translate(
          'I18N_PRE_EVENT_TITLE',
          'Stay tuned',
        ),
      }}
    />
    <h3
      className="textContainer inverted"
      dangerouslySetInnerHTML={{
        __html: translate(
          'I18N_PRE_EVENT_SUBTITLE',
          'You can control the crowd cameras once gates open',
        ),
      }}
    />
  </div>
);

export default PreEvent;
